import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';

import Layout from '../layout/Layout';
import {
    selectById as selectRewardById,
    suggest as suggestRewards,
} from '../../model/reward';
import {selectCurrentPharmacy} from '../../model/pharmacy';
import {selectUser} from '../../model/profile';
import RewardOrderForm from '../domains/reward/RewardOrderForm';
import RewardDetailTeaser from '../domains/reward/RewardDetailTeaser';
import RewardSuggestions from '../domains/reward/RewardSuggestions';
import Lightbox from '../components/Lightbox';
import {submitRewardOrder} from '../../model/reward';
import OrderSuccessModal from '../domains/reward/OrderSuccessModal';

// NOTE: no page query needed because static reward data is being sourced into the
// redux store, see gatsby-ssr.js, gatsby-browser.js and SourceStaticRewards.js

export default function RewardPage({pageContext}) {
    const id = +pageContext.id; // make it a number
    const rewardsById = useSelector(selectRewardById);
    const dispatch = useDispatch();
    const [isLightBoxOpen, openOrderForm] = useState(false);
    const reward = rewardsById[id];
    const suggestedRewards = suggestRewards(rewardsById, id);
    const currentPharmacy = useSelector(selectCurrentPharmacy);
    const user = useSelector(selectUser);
    const pharmacyAddress = currentPharmacy ? (
        <>
            {currentPharmacy.name}
            <br />
            {currentPharmacy.address}
            <br />
            {currentPharmacy.zip}
            <br />
            {currentPharmacy.city}
        </>
    ) : null;
    return (
        <Layout>
            <RewardDetailTeaser
                reward={reward}
                onButtonClick={() => openOrderForm(true)}
            />
            <RewardSuggestions suggestedRewards={suggestedRewards} />
            <Lightbox
                maxWidth="25rem"
                isOpen={isLightBoxOpen}
                onClose={() => openOrderForm(false)}>
                <RewardOrderForm
                    isLightBoxOpen={isLightBoxOpen}
                    isValid={user && user.points >= reward.points}
                    reward={reward}
                    pharmacyAddress={pharmacyAddress}
                    userEmail={user && user.email}
                    onSubmit={() => {
                        dispatch(submitRewardOrder(id));
                        openOrderForm(false);
                    }}
                />
            </Lightbox>
            <OrderSuccessModal />
        </Layout>
    );
}

RewardPage.propTypes = {
    pageContext: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};
