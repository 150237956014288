import React from 'react';
import PropTypes from 'prop-types';

import {Section, Container, Row, Column} from '../../../layout/Grid';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import RewardTeaser from '../RewardTeaser';
import lang from '../../../../lang/pages/praemien.lang';

export default function RewardSuggestions({suggestedRewards}) {
    return (
        <Section theme="blue">
            <Container width="narrow" className="text-center">
                <Row>
                    <Column xs={12}>
                        <Headline type="h4">
                            {lang.moreRewardsHeadline}
                        </Headline>
                        <Paragraph>{lang.moreRewardsSub}</Paragraph>
                    </Column>
                </Row>
            </Container>
            <Container width="wide">
                <Row>
                    {/* don't render random rewards statically in order to avoid hydration issue */}
                    {(typeof window === 'undefined'
                        ? []
                        : suggestedRewards
                    ).map((reward) => (
                        <Column key={reward.id} xs={12} sm={6} md={6} lg={4}>
                            <RewardTeaser
                                title={reward.teaserHeadline}
                                desc={reward.teaserDescription}
                                image={reward.image}
                                points={reward.points}
                                route={reward.route}
                            />
                        </Column>
                    ))}
                </Row>
            </Container>
        </Section>
    );
}

RewardSuggestions.propTypes = {
    suggestedRewards: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            desc: PropTypes.string,
            image: PropTypes.string,
            points: PropTypes.number,
            route: PropTypes.string,
        })
    ),
};
