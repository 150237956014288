import styled from 'styled-components';

import {font} from '../../../../styles/variables';

export const PointsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Points = styled.span`
    font-weight: ${font.weight.bold};
    margin-left: 0.5rem;
`;
