import React from 'react';
import PropTypes from 'prop-types';
import {sagaModal} from 'redux-saga-modal';
import Lightbox from '../../../components/Lightbox';
import {Container, Row, Column} from '../../../layout/Grid';
import {ReactComponent as Present} from '../../../../assets/images/praemien/present.svg';
import lang from '../../../../lang/pages/praemien.lang';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import Link from '../../../components/Link';
import {routes} from '../../../../model/navigation';

const OrderSuccessModal = ({isOpen, hide, type}) => {
    const typeLang = (type) =>
        type === 'voucher'
            ? lang.voucherModal
            : type === 'product'
            ? lang.productModal
            : null;

    return (
        <Lightbox maxWidth="25rem" isOpen={isOpen} onClose={() => hide()}>
            <Container width="narrow">
                <Row>
                    <Column md={12} className="text-center">
                        <Present />
                        <Headline type="h6">{typeLang(type).headline}</Headline>
                        <Paragraph size="small">
                            {typeLang(type).thanks}
                        </Paragraph>
                        <Link showAsButton href={routes.trainings}>
                            {lang.zurTrainingskollektion}
                        </Link>
                    </Column>
                </Row>
            </Container>
        </Lightbox>
    );
};

OrderSuccessModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

export default sagaModal({
    name: 'ORDER_SUCCESS_MODAL',
})(OrderSuccessModal);
