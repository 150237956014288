import React from 'react';
import PropTypes from 'prop-types';

import {Container, Row, Column} from '../../../layout/Grid';
import Paragraph from '../../../components/Paragraph';
import Headline from '../../../components/Headline';
import Image from '../../../components/Image';
import Button from '../../../components/Button';
import ErrorMessage from '../../../components/Form/ErrorMessage';
import lang from '../../../../lang/pages/praemien.lang';
import {makeUrl} from '../../../../model/backend-api';
import {ReactComponent as Present} from '../../../../assets/images/praemien/present.svg';

export default function RewardOrderForm({
    pharmacyAddress,
    userEmail,
    onSubmit,
    isValid,
    reward,
}) {
    const {image, teaserHeadline, type} = reward;
    return (
        <Container width="narrow">
            <Row>
                <Column xs={12} className="text-center">
                    <Present />
                    <Headline type="h6">{lang.lightboxHeadline}</Headline>
                    <Paragraph size="small">
                        {type === 'product'
                            ? lang.lightboxRewardText
                            : lang.lightboxVoucherText}
                    </Paragraph>
                    <Image
                        src={makeUrl(image)}
                        alt={teaserHeadline}
                        responsive
                    />
                    <Paragraph align="center" size="small">
                        {type === 'product' ? pharmacyAddress : userEmail}
                    </Paragraph>
                    <Button
                        onClick={onSubmit}
                        label={lang.confirm}
                        disabled={!isValid}
                    />
                    {!isValid && (
                        <ErrorMessage>
                            {lang.orderFailure.msgNotEnoughPoints}
                        </ErrorMessage>
                    )}
                </Column>
                <Column xs={12}>
                    <Paragraph align="center" size="small">
                        {type === 'product'
                            ? lang.checkPharmacy
                            : lang.checkEmail}
                    </Paragraph>
                </Column>
            </Row>
        </Container>
    );
}

RewardOrderForm.propTypes = {
    userEmail: PropTypes.string,
    pharmacyAddress: PropTypes.element,
    onSubmit: PropTypes.func,
    isValid: PropTypes.bool,
    reward: PropTypes.object,
};

RewardOrderForm.defaultProps = {
    onSubmit: () => {},
};
