import React from 'react';
import PropTypes from 'prop-types';

import {Section, Container, Row, Column} from '../../../layout/Grid';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import Button from '../../../components/Button';
import Link from '../../../components/Link';
import Icon from '../../../components/Icon';
import {color} from '../../../../styles/variables';
import Image from '../../../components/Image';
import {makeUrl} from '../../../../model/backend-api';
import {parseHtmlToReact} from '../../../../utils/html-react-parser';
import {routes} from '../../../../model/navigation/routes';
import lang from '../../../../lang/pages/praemien.lang';
import {PointsWrapper, Points} from './styles';

const htmlNodeReplacements = {
    p: Paragraph,
};
export default function RewardDetailTeaser({reward, onButtonClick}) {
    const {detailsHeadline, detailsDescription, image, points} = reward;

    return (
        <Section>
            <Container width="medium">
                <Link href={routes.praemien} icon="arrowBack" iconLeft>
                    zurück
                </Link>
            </Container>
            <Container width="narrow" className="text-center">
                <Row>
                    <Column xs={12}>
                        <Headline type="h1">{detailsHeadline}</Headline>
                        {parseHtmlToReact(
                            detailsDescription,
                            htmlNodeReplacements
                        )}
                        <Image
                            src={makeUrl(image)}
                            alt={detailsHeadline}
                            responsive
                        />
                        <PointsWrapper>
                            <Icon
                                name="starFilled"
                                size="1.5rem"
                                color={color.petrol}
                            />
                            <Points>{points}</Points>
                        </PointsWrapper>
                    </Column>
                    <Column xs={12} className="text-center">
                        <Button
                            onClick={onButtonClick}
                            label={lang.orderButton}
                        />
                    </Column>
                </Row>
            </Container>
        </Section>
    );
}

RewardDetailTeaser.propTypes = {
    reward: PropTypes.shape({
        detailsDescription: PropTypes.any,
        image: PropTypes.any,
        points: PropTypes.any,
        detailsHeadline: PropTypes.any,
    }),
    onButtonClick: PropTypes.func,
};
